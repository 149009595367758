.netZeroCardMain {
    background: white;
    border-radius: 15px !important;
    padding: 20px !important;
    border: 1px solid var(--netZeroCardBorder)
}

.netZeroCardTitle {
    color: var(--netZeroCardTitle);
    font-weight: 800;
    font-size: 16px !important;
    margin-bottom: 0px !important;
}

.netZeroCardSubTitle {
    font-size: 12px !important;
    margin-left: 10px;
}

.netZeroCommonTitle {
    color: var(--netZeroCommonTitle);
    margin-bottom: 0px !important;
}

.netZeroCurrentImpact {
    margin-bottom: 0px !important;
}

.netZeroCurrentImpactValue {
    color: "#8B9097";
    font-size: 18px;
    font-weight: 500;
}

.netZeroCurrentSimulatedImpact {
    color: "#535D6D";
    font-size: 18px;
    font-weight: 800;
}

.netZeroImpactReductionValue {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background: rgba(38, 132, 255, 0.4);
    width: fit-content;
    padding: 7px 3px 7px 7px;
    border-radius: 5px;
    color: white !important;
}

.netZeroImpactReductionValueMain {
    display: flex !important;
    align-items: center;
}

.netZeroReduction {
    color: #2684FF !important;
    font-size: 14px !important;
    margin-right: 5px;
}

.netZeroReductionDownArrow {
    color: #2684FF !important;
}

.netZeroImpactCostValue {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background: rgba(45, 175, 50, 0.2);
    width: fit-content;
    padding: 7px 3px 7px 7px;
    border-radius: 5px;
    color: white !important;
}

.netZeroCostReduction {
    color: #2DAF32 !important;
    font-size: 11px !important;
    margin-right: 5px;
}

.netZeroCostReductionDownArrow {
    color: #2DAF32 !important;
}

.netZeroProdSize {
    border: 1px solid #2DAF32;
    color: #2DAF32 !important;
    margin: 10px !important;
    border-radius: 5px;
}

.bottomMiddleTitle {
    display: flex !important;
    justify-content: center;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -15px;
}

.netZeroMiddleCardTitle {
    background-color: #fff;
    padding: 5px 10px 5px 10px;
}

.netZeroCurrentBottomImpact {
    margin-bottom: 0px !important;
    font-size: 15px;
    font-style: italic;
}

.netZeroCurrentBottomImpactValue {
    color: "#8B9097";
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}

.netZeroCommonBottomTitle {
    color: var(--netZeroCommonTitle);
    margin-bottom: 0px !important;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
}

.netZeroCurrentBottomSimulatedImpact {
    color: var(--netZeroCommonTitle);
    margin-bottom: 0px !important;
    font-size: 18px;
    font-style: italic;
}

.netZeroTopView {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.netZeroTitle {
    color: var(--netZeroHeaderTitle) !important;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px !important;
}