.table {
  border-radius: 4px !important;
  overflow: hidden;
}

.tbleHeader {
  background-color: var(--tableHeaderColor) !important;
  color: var(--tableHeaderText) !important;
}

.tbleHeaderSimulate {
  background-color: #d2d7e0 !important;
  color: #535d6d !important;
}

.tbleR {
  background-color: #fff;
  color: var(--mainTextInptColor);
  border-color: var(--mainTextInptColor);
  vertical-align: top !important;
}

.iconPM {
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-left: 10px !important;
  border: none;
  display: inline-block;
  animation: blink 2s ease-in infinite;
}

.ui.table tfoot tr:first-child>th:only-child {
  padding: 20px !important;
  background:#fefefe;
}

@keyframes blink {

  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}