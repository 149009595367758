.mainButtonContainerDark {
  text-align: start;
}
.mainButtonContainerGreen {
  text-align: start;
}

.buttonIcon {
  margin-right: 10px !important;
}

.commonButton {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff !important;
  padding: 15px 25px !important;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.commonOutLineButton {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff !important;
  padding: 14px 25px !important;
  border-radius: 4px;
  border-width: 1px solid;
}

.buttonGreenOutline {
  background-color: #ffffff !important;
  border: 1px solid var(--mainColor) !important ;
  color: var(--mainColor) !important;
}

.buttonDarkOutline {
  background-color: #ffffff !important;
  border: 1px solid #292b32 !important ;
  color: #292b32 !important;
}

.buttonBlueOutline {
  background-color: #ffffff !important;
  border: 1px solid #327ab7 !important ;
  color: #327ab7 !important;
}

.buttonRedOutline {
  background-color: #ffffff !important;
  border: 1px solid #ff6666 !important ;
  color: #ff6666 !important;
}

.buttonDark {
  background-color: #292b32 !important;
}

.buttonGreen {
  background-color: var(--buttonColor) !important;
}

.buttonBlue {
  background-color: #327ab7 !important;
}

.buttonRed {
  background-color: #ff6666 !important;
}

.disableButtonMain {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
}

.disableButtonOutlineMain {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  border-radius: 4px;
  border-width: 1px;
  width: fit-content;
  cursor: pointer;
}

.imageCustomView{
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.iconChildMain{
  margin-right: 5px !important;
  display: flex;
}

.iconChildTextMain{
  font-family: "ProximaNova";
  font-size: 14px;
  color: #ffffff;
  display: flex;
}

.iconChildMainContainer{
  display: flex;
}

.bouncing-button {
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}