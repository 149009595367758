.productDetailsViewMain {
  background: #fff !important;
  border-radius: 5px;
}

.productDetailsInsert {
  margin: 15px;
}

.productDetailsButtonView {
  margin-top: 15px;
}

.userDetailsRoles {
  margin-top: 0px !important;
}
