// .webNotificationDiv {
//     margin-right: 20px;
//   }


.popover-container {
  position: relative;
  display: inline-block;
}

.popover-trigger {
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 15px;
  background: transparent !important;
  margin-top: 10px;
}

.popover-content {
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translateX(-50%);
  margin-top: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 2px var(--popOverContentBoarder);
  border-radius: 4px;
  padding: 15px;
  z-index: 1000;
  white-space: nowrap;
}

.popover-content-arrow {
  content: "";
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent var(--popOverContentArrow) transparent;
}

.popOverContentMain{
  min-width: 20px;
  min-height: 20px;
}