.editable-table {
  margin-top: 1em !important;

  thead th {
    background-color: var(--tableHeaderColor) !important;
    color: var(--tableHeaderText) !important;
    min-width: 100px !important;
  }

  tbody td {
    text-align: center;
  }

  tbody tr td .custom-icon-button {
    margin: 0 0.2em;
  }

  tfoot th {
    background: #FFFFFF !important;
  }
}
.non-editable-table {
  margin-top: 1em !important;

  thead th {
    background-color: #D2D7E0 !important;
    color: #535D6D !important;
  }

  tbody td {
    text-align: center;
  }

  tbody tr td .custom-icon-button {
    margin: 0 0.2em;
  }

  tfoot th {
    background: #FFFFFF !important;
  }
}

.edited {
  color: #2684FF !important;
}

.custom-grid-main {
  margin-top: 0px !important;
}

.packaging-add-button {
  display: flex;
  justify-content: end;
}

.empty-block-view {
  margin-top: 86px !important;
}

.empty-block {
  margin-top: 94px !important;
}