@import "../../../config/globalStyles.scss";

.create-panel {
    align-items: center !important;
    padding: 0px !important;
    margin-bottom: 5px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    min-height: 60px;
}

.ui.secondary.pointing.menu {
    background-color: var(--mappingCategoryTab) !important;
    min-height: 50px !important;

    .active.item {
        color: var(--activeProductTab) !important;
        border-bottom-color:  var(--activeProductTab) !important;
    }

    a.item:hover {
        color: var(--activeProductTab) !important;
    }

    .item {
        color: $light-dark-green;
    }
}

.tab-side-menu {
    padding: 0px !important;
  
}

.ui.visible.push.sidebar {
    background:var(--mappingSidePanel) !important ;
 }

.tab-segment {
    padding: 1em 1em;
    margin: 1rem 0;
}

.side-menu-header {
    margin: 0px !important;
    display: grid !important;
    grid-template-columns: 300px 10px !important;
    max-height: 50px;
    h2 {
        color: $light-dark-green !important;
        align-items: center !important;
    }

    i {
        margin-top: 0.3rem !important;
        color: $light-dark-green !important;
        
    }
    i:hover {
        cursor: pointer;
    } 
}