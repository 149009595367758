.imageMain {
  height: 50px !important;
  margin-bottom: 20px;
}

.imageUploaderMain {
  margin-bottom: 20px;
  flex-direction: column;
}

.imageViewMainOther {
  margin-bottom: 10px;
  margin-top: 4px;
  height: 100px;
  object-fit: contain;
}

.custom-file-upload {
  font-family: "ProximaNova";
  border: 1px solid var(--mainColor);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffff;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  text-align: center;
}

.file-preview {
  margin: 0 10px;
}

.labelUploder {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInptColor);
  margin-bottom: 5px !important;
}

.image {
  margin-right: 5px;
}

.deleteButtonContentMain {
  top: 50px;
  left: 100px;
  z-index: 1000;
  position:absolute
}