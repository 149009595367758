.titleViewMain {
  border-bottom: 1px solid var(--titleBorderColor);
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  margin-right: 1rem !important;
  padding-left: 5px;
  padding-right: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-bottom: 10px !important;
  &.active {
    background-color: var(--titleActiveBackground);
  }
}

.textTitle {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--titleColor);

  &.active {
    color: var(--titleActiveColor);
  }
}

.plusIcon {
  cursor: pointer;
  color: var(--mainColor) !important;

  &.active {
    color: #fff !important;
  }
}