.checkBoxSimulationTable {

    .ui.checkbox .box:before,
    .ui.checkbox label:before {
        border: 1px solid var(--mainColor);
    }

    .ui.checkbox input:checked~.box:after,
    .ui.checkbox input:checked~label:after {
        color: var(--mainColor)
    }
}