.notificationContentMain {
  min-width: 400px !important;
  min-height: 500px !important;
}

.notificationArrow {
  left: 55% !important;
}

.notificationDate {
  text-align: end !important
}

.notificationCardMain {}

.notificationContentView {
  max-height: 450px;
  overflow: scroll;
  scrollbar-width: none;
}

.notificationCustomPopOver {
  padding: 0px !important;
}

.notificationCardItem {
  padding: 10px 15px !important;
  background: #F9FFF3;
  border-bottom: 1px solid var(--notificationShadow);
  box-shadow: 10px 2px 2px var(--notificationShadow);
}

.notificationCardItemRead {
  padding: 10px 15px !important;
  background: #ffffff;
  border-bottom: 1px solid var(--notificationShadow);
  box-shadow: 10px 2px 2px var(--notificationShadow);
}

.notificationHeader {
  margin: 0px !important;
  border-bottom: 1px solid var(--notificationShadow);
}

.notificationHeaderText {
  text-align: center !important;
  font-weight: 800;
  font-size: 18px;
  color: var(--notificationHeaderText) !important
}

.notificationTitle {
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  font-weight: 700;
  color: var(--notificationTitleText) !important
}

.notificationBottom {
  margin: 0px !important;
  background: #ffffff;
  border-top: 1px solid var(--notificationShadow);
  text-align: center !important;
}