html {
  height: 100% !important;
  font-family: sans-serif;
}

.viewMain {
  overflow: scroll !important;
  margin: 0px !important;
  display: flex !important;
}

.leftLayoutContent {
  margin-top: 35px;
  background-color: white;
  width: 80px !important;
  align-items: center;
}

.rightContentMain {
  padding-top: 10px;
  margin-top: 30px !important;
  overflow-y: auto;
  background-color: #f9f9f4;
  flex: 1;
}

.customMainBottomContainer {
  height: calc(100vh - 80px);
  padding: 20px;
  overflow-y: scroll !important;
  scrollbar-width: none;
}

@media screen and (max-width: 991px) {
  .contentHide {
    display: none;
  }
}