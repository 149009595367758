.customLabelFilter {
  margin-top: 5px !important;
}

.customButtonSearch {
  margin-top: 24px;
}

.customProductFilterMainView {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

@media screen and (max-width: 991px) {
  .customButtonSearch {
    margin-top: 0px;
  }
}
