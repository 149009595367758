.productDetailsViewMain {
  background: #fff !important;
  border-radius: 5px;
}

.productDetailsInsert {
  margin: 15px;
}

.productDetailsButtonView {
  margin-top: 15px;
}

.action_button {
  background-color: var(--mainColor) !important;
  color: #fff !important;
}