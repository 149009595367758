.removeInputPadding {
  padding-bottom: 5px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.customSupplierUpdate {
  margin-right: 20px !important;
}

.supplierEditMainButton {
  display: flex !important;
  margin-top: 30px !important;
  padding: 1rem;
  justify-content: flex-end;
}

.supplierCustomAddress{
  min-height: 125px !important;
}

.supplierCustomOther{
  min-height: 85px !important;
}

.supplierEditMain {
  margin: 0px !important;
}

.customCheckpointSupplierUser{
  .ui.checkbox label {
    cursor: auto;
    position: relative;
    display: block;
    padding-left: 35px;
    outline: 0;
    font-size: 15px !important;
    padding-top: 5px;
    color: var(--mainTextInputHeader)
}
.ui.checkbox .box:before, .ui.checkbox label:before {
  width: 22px;
  height: 22px;
}
.ui.checkbox label:after {
  font-size: 18px;
  width: 22px;
  height: 25px;
  padding-top: 2px;
}
.ui.checkbox input:checked~label:after {
  color: var(--mainColor) !important;
}
}