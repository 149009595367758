.removeInputPadding {
  padding-bottom: 5px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.customSupplierUpdate {
  margin-right: 20px !important;
}

.supplierEditMainButton {
  display: flex !important;
  margin-top: 30px !important;
}

.customSupplierDetailsInput {
  border-width: 0px !important;
  padding: 0px !important;
  color: var(--mainTextInputColorWithDisable) !important;
  margin-top: 5px;
}

.supplierDetailsView {
  padding-bottom: 0px !important;
}

.supplierAddCustomIngredientTitle {
  margin-bottom: 0px;
}

.registerSupplierRegister {
  margin-top: 5px;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInputHeader);
}