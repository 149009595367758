.offerModalView {
  display: flex;
  justify-content: flex-end;
}

.commonModalIcon {
  padding-right: 1rem;
  color: var(--mainColor);
  cursor: pointer;
}

.commonModalMain {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  display: flex !important;
}

.offerModalSubmitView {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px !important;
}

.offerModalOfferValue {
  min-height: 50px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 20px !important;
  max-width: 150px;
}

.offerModalOfferValueMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offerModalOfferValueIcon {
  margin-bottom: 0px !important;
  margin-right: 10px !important;
  margin-top: -15px !important;
}

.offerModalOfferValueError {
  display: flex;
  justify-content: center;
}

.commonModalTitle {
  font-family: "ProximaNovaBold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: var(--commonModalHeader);
  padding-left: 1rem;
}

.offerModalOfferSubmitValue {
  font-family: "ProximaNova" !important;
  font-weight: 600 !important;
  text-align: "left";
  margin-bottom: 5px !important;
  margin-top: 15px !important;
}

.offerModalOfferValueMainSubmit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: 10px;
}

.offerModalOfferValueIconSubmit {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: "ProximaNova" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  border: 1px solid lightgray;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.offerModalOfferValueSubmit {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: "ProximaNova" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  border: 1px solid lightgray;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.offerModalOfferDetailsSubmit {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

.offerModalOfferDetailsText {
  font-family: "ProximaNova" !important;
  font-weight: 600 !important;
  text-align: right;
}

.offerModalOfferDetailsTextLeft {
  font-family: "ProximaNova" !important;
  font-weight: 600 !important;
}

.offerModalBoldText {
  font-weight: 800 !important;
}

.modalDimmer {
  opacity: 0.2 !important;
}

.dimmer {
  background-color: rgba(83, 93, 109, 0.4) !important;
}

.modal-description {
  height: 100% !important;
}

.buttonGroup {
  display: flex;
}

.customDimmerLayoutLarge {
  left: 50% !important;
  margin: 0px !important;
  width: 50% !important;
  height: 100%;
  background: #ffffff !important;
}

.customDimmerLayoutMiddle {
  left: 25% !important;
  margin: 0px !important;
  width: 75% !important;
  height: 100%;
  background: #ffffff !important;
}

.customDimmerLayoutSmall {
  left: 0 !important;
  margin: 0px !important;
  width: 100% !important;
  height: 100%;
  background: #ffffff !important;
}

.commonModalCloseIcon {
  cursor: pointer !important;
  margin-right: 15px;
}
