.productProgressMain {
  display: block !important;
  margin-bottom: 25px !important;
}

.productSummaryPageCustomButton {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.viewNumberOfClimate {
  font-size: 60px;
  margin-bottom: 0px !important;
  text-align: center;
  color: var(--mainColor);
  font-family: "ProximaNova";
  font-style: bold;
  font-weight: 800;
}

.viewNumberOfClimateSub {
  font-size: 12px;
  margin-top: 0px !important;
  text-align: center;
  color: var(--mainTextInptColor);
  font-family: "ProximaNova";
  font-weight: bold;
}

.climateDetailsView {
  background: #fff8ec;
  padding: 20px;
  border-radius: 10px;
}

.viewBottomClimateDetails {
  font-size: 18px;
  margin-top: 20px !important;
  text-align: center;
  color: var(--mainTextInptColor);
  font-family: "ProximaNova";
  font-weight: bold;
}

.productDetailsView {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: center;
}

.productDetailsViewOther {
  margin-top: 5px !important;
  text-align: center;
  margin-bottom: 10px;
}

.productQrDetails {
  margin-top: 30px !important;
  text-align: center;
  margin-bottom: 10px;
}

.productNameDetails {
  font-size: 16px;
  color: var(--mainColor);
  font-family: "ProximaNova";
  font-weight: 700;
  margin-bottom: 5px;
}

.productCodeDetails {
  font-size: 14px;
  color: var(--mainTextInptColor);
  font-family: "ProximaNova";
  font-weight: 600;
  margin-bottom: 5px;
}

.productCodeNumberView {
  font-size: 14px;
  color: #2684ff;
  font-family: "ProximaNova";
  font-weight: 600;
  margin-bottom: 5px;
}

.productOtherDetails {
  font-size: 14px;
  color: var(--mainTextInptColor);
  font-family: "ProximaNova";
  font-weight: 600;
  margin-bottom: 5px;
}

.addProductIngredientView {
  background: #f9f9f4 !important;
}

.addViewDetails {
  margin-right: 15px !important;
}

.productSummaryButtonOther {
  border: 0px !important;
}

.productSummaryBarChat {
  margin-top: 15px;
  .bar-chart {
    height: 400px !important;
    min-height: auto;
  }

  .ui.segment {
    height: 450px !important;
  }

}