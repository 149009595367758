.selectBoxContent {
  margin-top: 0px;
  margin-bottom: 25px;
  .ui.card > .content > .header + .description,
  .ui.card > .content > .meta + .description,
  .ui.cards > .card > .content > .header + .description,
  .ui.checkbox input.hidden + label {
    font-family: "ProximaNova" !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    background: #fff;
    color: #707070;
  }
}

.customGridColumnEBN {
  margin-bottom: 0px !important;
}

.imageRegisterMain {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 5vw);
  grid-gap: 15px;
}

.imageRegisterView {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px !important;
}
.imageRegisterViewMain {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 8;
}

input[type="file"] {
  display: none;
}

.productRegisterButtonView {
  display: flex !important;
  margin-top: 20px !important;
  margin-bottom: 100px;
}

.productRegisterButtonCustom {
  display: flex !important;
  margin-top: 50px !important;
  min-height: 50px;
  align-items: center !important;
}
.addUnitSize {
  margin-top: 25px !important;
}

.productRegisterTable {
  margin-top: 20px !important;
}

.productRegisterTitle {
  margin-bottom: 8px !important;
}

.modalRegisterButtonMain {
  display: flex;
  flex-direction: row;
  margin-top: 20;
  align-items: center;
}

.modalRegisterFlex {
  flex: 1;
}

.modalRegisterButtonLeft {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.productRegisterUnitAddButton {
}

.customProductImageView {
  border: 1px solid var(--mainTextInputBorderColor);
  width: 120px;
  height: auto;
  border-radius: 5px;
}

.customViewTypeMain {
  padding-top: 2px !important;
}

.customGridColomnType {
  margin-top: 5px !important;
}

.productUnitAddButton{
  margin-top: 2px;
  padding: 10px 20px 10px 20px !important;
}

@media screen and (max-width: 991px) {

}
